import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import styles from "assets/jss/material-kit-react/views/loginPage.js";

import image from "assets/img/bg2.jpg";
import myescapeImage from "assets/img/faces/my-escape-cover.jpg";
import myescapeBackImage from "assets/img/myescape_back.jpg";
import 'assets/css/fa.css';
import "assets/scss/material-kit-react.scss";
import SEO from "../components/seo";

const useStyles = makeStyles(styles);

export default function LoginPage(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="transparent"
        brand="Duaa Rasho"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "white"
        }}
        {...rest}
      />
      <SEO description={"This book was born from personal experience. For a woman who made the decision to migrate alone. Experiences have been documented in this book. "} image={myescapeImage} pathname="/myescape/"></SEO>
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8}>
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form}>
                  <div className={classes.alignHeader}>
                    <CardHeader color="primary" className={classes.cardHeader}
                      style={{
                        backgroundImage: "url(" + myescapeImage + ")",
                        backgroundSize: "cover",
                        backgroundPosition: "top center"
                      }}>

                    </CardHeader>
                    <CardHeader color="primary" className={classes.cardHeader2}
                      style={{
                        backgroundImage: "url(" + myescapeBackImage + ")",
                        backgroundSize: "cover",
                        backgroundPosition: "top center"
                      }}>

                    </CardHeader>
                  </div>
                  <CardBody>
                    <div className={classes.description}>
                      <p>
                        We do not choose our destinies.
          Life leads us to choices that are forced upon.
          It wasn't an easy decision, it's like putting your life in a game of gambling
          in order to live as a human.
          Wars, conflicts, death, smugglers and much more.
          My story is one of thousands of stories, for women who want to survive with what remains of their humanity.
          Every new day begins, there is hope.
          When delivering a death hole, it is an achievement.
          The woman who will read my book will know that she was never alone struggling with difficulties.
          My name is Duaa Rasho , and thats my story.
              </p>
                    </div>
                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    <Button 
                      target="_blank"
                      href="https://www.amazon.de/dp/383162156X/ref=sr_1_1?keywords=duaa+rasho&qid=1578777969&sr=8-1" 
                      round 
                      color="primary" 
                      size="lg" 
                      style={{ width: "250px" }}>
                      <i className={` ${"fab fa-amazon"} ${classes.icons}`} /> Buy Now
                    </Button>
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
}
